var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-4"},[(_vm.exam && _vm.isStudent)?_c('Breadcrumb',[_c('template',{slot:"restPage"},[_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{ name: 'SATHistory' }}},[_vm._v(" SAT Exam History ")])],1),(_vm.exam)?_c('el-breadcrumb-item',[_c('router-link',{attrs:{"to":{
            name: 'SATResultPage',
            params: {
              userExamId: _vm.$route.params.userExamId
            }
          }}},[_vm._v(" "+_vm._s(_vm.exam.name)+" ")])],1):_vm._e(),(_vm.exam)?_c('el-breadcrumb-item',[_c('kbd',[_vm._v(_vm._s(_vm.questionOrder))])]):_vm._e(),_c('el-breadcrumb-item',[_vm._v(" Explanation ")])],1)],2):_vm._e(),(_vm.exam)?_c('Heading',{staticClass:"mb-3",attrs:{"content":`${_vm.exam.name} - Explanation`}}):_vm._e(),(_vm.exam)?_c('div',{staticClass:"question-title"},[_c('el-collapse',{attrs:{"accordion":""},model:{value:(_vm.sectionOrder),callback:function ($$v) {_vm.sectionOrder=$$v},expression:"sectionOrder"}},_vm._l((_vm.practices),function(practice,practiceIndex){return _c('el-collapse-item',{key:practiceIndex,attrs:{"name":practiceIndex + 1}},[_c('template',{slot:"title"},[_c('h6',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.titleCase(practice.name))+" ")])]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"showTags"},[_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Correct")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question wrong"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Wrong")])]),_c('div',{staticClass:"showTags-item"},[_c('span',{staticClass:"view_question empty"}),_c('span',{staticStyle:{"line-height":"1.8rem"}},[_vm._v("Unanswered")])])]),_c('div',_vm._l((practice.exam_questions),function(title,index){return _c('span',{key:index,class:{
                view_question: true,
                wrong: title.answer ? title.is_correct == 0 : false,
                empty: title.answer === null || title.answer === ''
              },on:{"click":function($event){return _vm.viewBrowse(practiceIndex + 1, index + 1)}}},[(title.id == _vm.question.id)?_c('span',{staticClass:"currentIcon questionsOrderActive"},[_c('i',{staticClass:"fa-solid fa-location-dot"})]):_vm._e(),_vm._v(" "+_vm._s(index + 1)+" ")])}),0)])],2)}),1)],1):_vm._e(),(_vm.question)?_c('div',{staticClass:"pt-3 mb-4"},[(_vm.question.question)?_c('div',{staticClass:"test-paper"},[(
          _vm.question.question &&
            _vm.question.question.type === 'single' &&
            _vm.question.question.passage_id > 0 &&
            _vm.question.question.passage.content
        )?_c('MultipleChoiceWithPassage',{attrs:{"mode":"explanation","passageIntro":_vm.question.question.passage.introduction
            ? _vm.question.question.passage.introduction.intro
            : null,"passageContent":_vm.getPassage,"questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.question.answer ? _vm.question.answer : '/',"correctAnswer":_vm.getCAnswers(_vm.question.question.answers),"isCorrect":_vm.question.answer ? _vm.question.is_correct == 1 : false,"explanation":_vm.question.question.explanations.length > 0
            ? _vm.question.question.explanations[0].explanation
            : '-',"isFree":false,"company":_vm.CompanyName,"email":_vm.CompanyEmail,"showMark":false,"showLine":false,"activities":_vm.question.answer && _vm.question.metadata
            ? _vm.question.metadata.activities
            : null}},[_c('AI',{staticClass:"pl-2",attrs:{"content":`Explanation`,"questionId":_vm.questionId,"questionOrder":_vm.questionOrder}})],1):(
          _vm.question.question &&
            _vm.question.question.type === 'single' &&
            (_vm.question.question.passage === null ||
              _vm.question.question.passage.content === null)
        )?_c('MultipleChoice',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.question.answer ? _vm.question.answer : '/',"correctAnswer":_vm.getCAnswers(_vm.question.question.answers),"isCorrect":_vm.question.answer ? _vm.question.is_correct == 1 : false,"explanation":_vm.question.question.explanations.length > 0
            ? _vm.question.question.explanations[0].explanation
            : '-',"isFree":false,"company":_vm.CompanyName,"email":_vm.CompanyEmail,"showMark":false,"activities":_vm.question.answer && _vm.question.metadata
            ? _vm.question.metadata.activities
            : null}},[_c('AI',{staticClass:"pt-3",attrs:{"content":`Explanation`,"questionId":_vm.questionId,"questionOrder":_vm.questionOrder}})],1):(
          _vm.question.question &&
            _vm.question.question.type === 'math' &&
            _vm.getCAnswers(_vm.question.question.answers)
        )?_c('Math',{attrs:{"mode":"explanation","questionOrder":_vm.questionOrder,"content":_vm.question.question.content,"options":_vm.question.question.options,"answer":_vm.question.answer !== null ? _vm.question.answer : '/',"correctAnswer":_vm.getCAnswers(_vm.question.question.answers),"isCorrect":_vm.question.answer ? _vm.question.is_correct == 1 : false,"explanation":_vm.question.question.explanations.length > 0
            ? _vm.question.question.explanations[0].explanation
            : '-',"isFree":false,"showMark":false,"company":_vm.CompanyName,"email":_vm.CompanyEmail,"activities":_vm.question.answer && _vm.question.metadata
            ? _vm.question.metadata.activities
            : null}},[_c('AI',{staticClass:"pt-3",attrs:{"content":`Explanation`,"questionId":_vm.questionId,"questionOrder":_vm.questionOrder}})],1):_vm._e()],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }